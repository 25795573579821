import React, { useState,useEffect } from "react";
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';
import ReactDOM from "react-dom";
import useWindowScroll from "@react-hook/window-scroll";
import { useParams } from "react-router";
//import locale2 from 'locale2';
//import iso3166 from 'iso3166-1';
//import publicIp from 'public-ip';
import Popup from './compo/Popup';
//import Search from './Search';
import Querystring from './compo/Querystring';
import style from './compo/style';
import Slider from './compo/slider';
import './style/style.css';
import './style/fontawesome.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Masonry } from "masonic";

//const publicIp = require('public-ip');
/* (async () => {
	//console.log(await publicIp.v4());
	//=> '46.5.21.123'
	

	//console.log(await publicIp.v6());
	//=> 'fe80::200:f8ff:fe21:67cf'
})(); */
const App = () => {
	
	var witchArt = window.location.pathname.slice(1);
	const [name, setName] = Querystring('name', '');
	const [type, setType] = Querystring('type', '');
		
	//console.log(publicIp.v4());
	console.log(witchArt);
	const [items, setItems] = useState([]);
	const [details, setDetails] = useState([]);
	var queryApi = '';
	var toShow = '';
	  
	 //const items = [];
/* API to get article on Home page */
useEffect(() => {
	if(name == ''){
		queryApi = 'http://ebanzaro.com/api/articles/home/?code=e991decc6c3fdccedc1b4abfe97781a8&witchArt='+witchArt;
	}else{
		//queryApi = 'http://ebanzaro.com/api/articles/home/?code=e991decc6c3fdccedc1b4abfe97781a8&&iso=JPN';
		queryApi = 'http://ebanzaro.com/api/articles/search?code=e991decc6c3fdccedc1b4abfe97781a8&type='+type+'&name='+name;
	}
	fetch(queryApi).then(data => data.json()).then(result => {
	for (let k = 0; k < result.length; k++) {

		if(result[k]['article']){
			/* Set Limit for article words */
			//toShow = result[k]['article'].slice(0,180)+"<a href=\""+result[k]['slug']+"\" style=\"text-decoration: none;\"> ... more</a>";
			toShow = result[k]['article'].slice(0,180);
		}
		
			/* Set item array */
			items[k] = {id: k,name: result[k]['artName'],src: result[k]['articleSmallThumb'],article: toShow,landMarksHTML:result[k]['landMarksHTML'],countryName: result[k]['countryName'],StateName: result[k]['StateName'],slug:result[k]['slug'],articleImage:result[k]['articleImage'],countryFlag:result[k]['countryFlag'],countryMotto:result[k]['countryMotto']};
	}
	setDetails({details});
	//setItems({items});
	}) 
}, [name,type,witchArt])


 
  // Constructs the data for our grid items
 /* const [items] = React.useState(() =>
    Array.from(Array(4), () => ({
      id: i++,
      name: catNames.random(),
      src: randomChoice(cats)
    }))
  );  */

 if (items === null || items.length == 0) {
    return (
	<main className={style("container")}>
	 <Header />
      <div className={style("masonic")}>
		<div className="loader-wrap">
			<img className="loder-image" src="http://media.banzaro.com/loder-image.gif" />
		</div>
	</div>
     
	  
    </main>
	);
  }else{
  return (
    <main className={style("container")}>
	  <Router>
		
		<Switch>
			<Route path="/:arturl" children={<Child />} />
			
			<Route exact path="/">
			  <Slider />
			</Route>
		</Switch>
		<Header />
		
      <div className={style("masonic")}>
	  <ul className="flag-list">
		<HeaderCountry />
		</ul>
		
        <Masonry
          // Provides the data for our grid items
          items={items}
          // Adds 8px of space between the grid cells
          columnGutter={0}
          // Sets the minimum column width to 172px
          columnWidth={400}
          // Pre-renders 5 windows worth of content
          overscanBy={6}
          // This is the grid item component
          render={MonoCard}
        />
      </div>
	   
	   </Router>      
    </main>
  );
  }
};

function Child() {
	// We can use the `useParams` hook here to access
	// the dynamic pieces of the URL.
	
	let { arturl } = useParams();
	const [hat, setHat] = useState([]);		
	const [isOpen, setIsOpen] = useState(false);
	const [isOpens, setIsOpens] = useState(false);
 
	const toggleCountry = () => {
		setIsOpen(!isOpen);
	}
	const toggleState = () => {
		setIsOpens(!isOpens);
	}
	  
	useEffect(() => {
		fetch('http://ebanzaro.com/api/articles/artslug/?code=e991decc6c3fdccedc1b4abfe97781a8&slug='+arturl).then(data => data.json()).then(singleresult => {
			
			setHat(singleresult);
			
		}) 
	}, [arturl]);
	
	return (
		<>
		{hat.length > 0 ?hat.map((banz,i)=>
		<div className="detail-wrap">
			<div className="article-inner">
					{banz.articleThumbnail ? 
				<div className="image-wrap">
					<img className={style("img")} alt={banz.artName} src={banz.articleThumbnail} title={banz.artName} /> 
					<div className="detail-name-wrap">
					<h2 className="city"><Link key={banz.StateName} to="#" onClick={toggleState}>{banz.StateName}</Link></h2>					
					</div>
					<h2 className="country"><Link key={banz.countryName} to="#" onClick={toggleCountry}><img className={style("flag")} alt={banz.countryName} src={banz.countryFlag} /></Link></h2>
				</div>
					:
					
				<div className="text-wrap detail-text-wrap">		
					<h2 className="city"><Link key={banz.StateName} to="#" onClick={toggleState}>{banz.StateName}</Link></h2>
					<h2 className="country"><Link key={banz.countryName} to="#" onClick={toggleCountry}>{banz.countryName}</Link></h2>					
				</div>
					}
					<div className="text-wrap detail-text-wrap">
					<h3 className="title">{banz.artName}</h3>
					<div className="article-wrap" key="article" dangerouslySetInnerHTML={ {__html: banz.article} } />
					<div className="extrainfo" key="landmarks" dangerouslySetInnerHTML={ {__html: banz.landMarksHTML} } />
					</div>
		</div>
			
			
			{isOpen && <Popup
			  content={<>
				{banz.countryFlag ? <img className={style("flag")} alt={banz.countryName} src={banz.countryFlag} /> : ''}
				<h3 className="popup-title">{banz.countryName}</h3>
				<div dangerouslySetInnerHTML={ {__html: banz.countryDetails} } />
			  </>}
			  handleClose={toggleCountry}
			/>}
			{isOpens && <Popup
			  content={<>
				{banz.StateFlag ? <img className={style("flag")} alt={banz.StateName} src={banz.StateFlag} /> : ''}
				<h3 className="popup-title">{banz.StateName}</h3>
				<div dangerouslySetInnerHTML={ {__html: banz.stateDetails} } />
			  </>}
			  handleClose={toggleState}
			/>}
		</div>
		)
		:<Slider />}
		</>
		
	);
}


const MonoCard = ({ data: { id, name, src, article, countryName, StateName, landMarksHTML, slug, articleImage, countryFlag, countryMotto } }) => (
<div className="card-wrap">
  <div className={[style("card"), articleImage].join(' ')}>
	<div className="inner-wrap">
		{src ? 
		<div className="card-with-image">
			<a href={"/"+slug} className="image-wrap"><img className={style("img")} alt={name} src={src} title={name} /></a>
			<h2 className="country"><a href={"/search?type=country&name="+countryName}><img className="" alt={countryName} src={countryFlag} title={countryName+": "+countryMotto} /></a></h2>
			<div className="card-title-wrap">
				<span className="title" children={name} /> 	
				<span className="city" children={StateName} />
			</div>
		</div>
		: 
		<div className="card-without-image">
			<a href={"/"+slug} ><span className="title" children={name} /> 	</a>
			<span className="city" children={StateName} />
				{/* <a href={"/"+slug} className="readmore">Explore More</a> */}
		</div>
		}
		
		<div className="article-text-wrap">
			<div className="short-discreption" dangerouslySetInnerHTML={ {__html: article} } />	
			{/* <i className="fa fa-spinner fa-spin"></i>  */}
			{/* <a href={"/"+slug} className="readmore"><i className="fa fa-external-link"></i></a> */}
			
		</div>
	</div>
  </div>
</div>
);

const HeaderCountry = () => {
	
	const [flags, setFlage] = useState([]);
	var flagMoto = '';
	
	useEffect(() => {
		fetch('http://ebanzaro.com/api/articles/country-list/?code=e991decc6c3fdccedc1b4abfe97781a8').then(data => data.json()).then(result => {
			setFlage(result);
		}) 
	}, [])
		//console.log(flags);
	return(
	
		flags ? 
			flags.map((flg,i)=>
		<li>
				<a href={"/search?type=country&name="+flg.name}> <img className={style("flag")} src={flg.flag} alt={flg.name} title={flg.name+": "+flg.motto} height="30" /></a>
		</li>
			)
		: ''
	
	);
};


const Header = () => {
  //const scrollY = useWindowScroll(5);   
  const [list, setList] = useState('');
  const [szFor, szForSet] = Querystring('for', '');

  /* Use this variable as input type vale in search text box
  const [stringkey, setStringkey] = Querystring('for', '');  */
  
	/* Function to handle search query fron search box */
	function removeSearchList() {
		const concernedElement = document.querySelector(".searchresult");
		document.addEventListener("mousedown", (event) => {
		  if (!concernedElement.contains(event.target)) {
			//console.log("Clicked Outside / Elsewhere");
			setList('');
		  }
		});
	}
	function handleSearch(event) {

		/* check if key length is more then 3 */
		szForSet(event.target.value)
		if((event.target.value.length) > 2){
			fetch('http://ebanzaro.com/api/articles/search-list/?code=e991decc6c3fdccedc1b4abfe97781a8&key='+event.target.value).then(data => data.json()).then(sresult => {
			//console.warn(sresult);
				/* set search list in useState */
				setList(sresult);
			})
		}else{
			setList('');
		}
	}
	
	function clickSearch() {
		if(szFor){
			window.location.href="/search?type=place&name="+szFor+"&for="+szFor;
		}
	}

	return (
		<div className="header-wrap">
			<header className={style("header")}>
			<div className="logo">
			
			<Router>
			  <span role="img" aria-label="bricks">
				🧱
			  </span>{" "}
			  <a href="/">Banzaro</a></Router>
			 
			 </div>
			 <div className="saearch">
			  <input type="text" name="search" value={szFor} id="search" onChange={handleSearch} onBlur={removeSearchList} />
			  <input type="submit" onClick = {clickSearch} className="submit-search" />
			  <div className="searchresult" dangerouslySetInnerHTML={ {__html: list} } /> 
			</div>
			</header>
		</div>
	);
};

//const randomChoice = (items) => items[Math.floor(Math.random() * items.length)];
//let i = 0;

ReactDOM.unstable_createRoot(document.getElementById("root")).render(<App />);
//ReactDOM.unstable_createRoot(document.getElementById("root")).hydrate(<App />);
