import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const spanStyle = {
  //padding: '30px',
  //background: '#efefef',
  //color: '#000000'
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
 
}
const slideImages = [
  {
    url: 'https://media.banzaro.com/sliders/image2.jpg',
    caption: "2 Avonlea Village is located in New Glasgow, Prince Edward Island."
  },
  {
    url: 'https://media.banzaro.com/sliders/image5_0.jpg',
    caption: ""
  },{
    url: 'https://media.banzaro.com/sliders/image4_0.jpg',
    caption: "4 Igreja de São José de Macapá is a very old and well-established"
  },
];

const Slideshow = () => {
    return (
      <div className="slide-container">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div className="slid_img" style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                <div style={spanStyle}>{slideImage.caption != '' ? <span>{slideImage.caption}</span> : ''}</div>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    )
}

export default Slideshow; 