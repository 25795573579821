import React from "react";
import { styles } from "./theme";

	const style = styles({
	  masonic: `
		padding: 8px;
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		box-sizing: border-box;
		clear: both;
		padding-top: 80px;
	  `,
	  container: `
		min-height: 100vh;
		width: 100%;
	  `,
	  
	  header: ({ pad, color }) => `
		font-family: Quantico, sans-serif;
		font-size: 1.5rem;
		font-weight: 900;		
		color: ${color.body};
		top: 0;
		position: fixed;
		padding: 0 18px;
		z-index: 1000;
		width: 100%;
		text-align: center;
		transition: padding 200ms ease-in-out, background-color 200ms 200ms linear;
		background: #fff;
		border-bottom: 1px solid #f2f2f2;
		left:0;
		right:0;
    
	  `,
	  card: ({ shadow, color, pad, radius }) => `
		display: flex;
		flex-direction: column;		
		border-radius: ${radius.lg};
		justify-content: center;
		align-items: center;
		transition: transform 100ms ease-in-out;
		width: 100%;
		min-height: 100px;
		
	  `,
	  img: ({ radius }) => `
		width: 100%;
		display: block;			
	  `
	});

export default style;